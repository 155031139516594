@import '~antd/dist/antd.less';

.brand{
    z-index: 11;
    height: 64px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 24px;
    box-shadow: 0 1px 9px -3px rgb(0 0 0 / 20%);
    background-image: url('./images/logo_painel.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    .logo {
       width: 70%;
        margin: 16px 16px 40px 16px;

    }
}

// @primary-color: #1890ff; // primary color for all components
// @link-color: #1890ff; // link color
// @success-color: #52c41a; // success state color
// @warning-color: #faad14; // warning state color
// @error-color: #f5222d; // error state color
// @font-size-base: 14px; // major text font size
// @heading-color: rgba(0, 0, 0, 0.85); // heading text color
// @text-color: rgba(0, 0, 0, 0.65); // major text color
// @text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
// @disabled-color: rgba(0, 0, 0, 0.25); // disable state color
// @border-radius-base: 2px; // major border radius
// @border-color-base: #d9d9d9; // major border color
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
//   0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers


// .ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a{
//     color : #FFF
// }

@primary-color: #5BC4BF;@layout-sider-background: #FFF;@layout-header-background: #FFF;@table-header-bg: #004B52;@table-header-color: #FFF;