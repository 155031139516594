.button{
    width: 64px;
    height: 64px;
    line-height: 72px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease-out;
    &:hover {
      color: #000;
      background-color:  #efeff3;
    }
}

@primary-color: #5BC4BF;@layout-sider-background: #FFF;@layout-header-background: #FFF;@table-header-bg: #004B52;@table-header-color: #FFF;