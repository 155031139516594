body{
    background-color:   #004B52!important;
}

.login-container{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px!important;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

@primary-color: #5BC4BF;@layout-sider-background: #FFF;@layout-header-background: #FFF;@table-header-bg: #004B52;@table-header-color: #FFF;